import Skeleton from "@mui/material/Skeleton";
import { styled } from "@mui/material/styles";

import { ImageTile } from "../../ImageTile";
import { LinkWrapper } from "../../LinkWrapper";
import { MarketPlaceMinPrice } from "../../MarketPlaceMinPrice";
import { Typography } from "../../Typography";

export const MAX_TILE_IMAGE_WIDTH = 400;
export const DEFAULT_IMAGE_HEIGHT = 200;

export const ImageTileContainer = styled(ImageTile)({
    "&[data-with-overlay=true]": {
        "&::before": {
            content: '""',
            position: "absolute",
            backgroundColor: "rgba(0,0,0,0.2)",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2,
        },
    },
});

export const TileWrapper = styled(LinkWrapper)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    width: "100%",
    '&[data-is-small-tile="true"]': {
        flexDirection: "row",
    },
}));

export const TileWrapperHorizontal = styled(TileWrapper)(({ theme }) => ({
    flexDirection: "column",
    '&[data-is-small-tile="true"]': {
        flexDirection: "row",
    },
    [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
    },
}));

export const TileContentWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(2),
}));

export const TileContentWrapperSkeleton = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(1),
    "&[data-tile-content=row]": {
        flexDirection: "column",
    },
}));

export const TileContentDetailsWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flex: 1,
    gap: theme.spacing(1),
    flexDirection: "column",
}));

export const TileContentDetailsSpacingWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingBlockEnd: theme.spacing(2),
    paddingInline: theme.spacing(1.5),
}));

export const TitleText = styled(Typography)({
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
    WebkitLineClamp: 2,
});

export const ImageTileHeaderContainer = styled("div")(({ theme }) => ({
    position: "absolute",
    top: 0,
    zIndex: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    padding: theme.spacing(1),
}));

export const MarketMinPriceStyledVertical = styled(MarketPlaceMinPrice)({
    alignItems: "flex-end",
    textAlign: "end",
});
export const ImageSkeleton = styled(Skeleton)(({ theme }) => ({
    borderRadius: theme.roundness,
}));
export const FixedSkeleton = styled(Skeleton)({ transform: "unset" });

export const MarketPlaceProductTileImageHorizontalContainer = styled("div")({
    '& div[data-ref="product-image"]': {
        borderRadius: 0,
        "&::before": {
            borderRadius: 0,
        },
    },
});

export const MarketPlaceProductWrapper = styled("div")(({ theme }) => ({
    boxShadow: "0 2px 8px -2px rgba(0,0,0,.1)",
    borderRadius: theme.roundness,
    background: theme.palette.common.white,
    overflow: "hidden",
    height: "100%",
    transition: "box-shadow 0.3s",
    "&:hover": {
        boxShadow: "0 4px 12px -4px rgba(0,0,0,.25)",
    },
}));
