import { useCallback, useRef } from "react";

export function useOnlyFirstCall<T extends (...args: any[]) => void>(fn: T) {
    const functionCalledRef = useRef(false);

    return useCallback(
        (...args: Parameters<T>) => {
            if (!functionCalledRef.current) {
                functionCalledRef.current = true;
                fn(...args);
            }
        },
        [fn]
    );
}
