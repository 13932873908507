import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

import { AssetUrl } from "@holibob-packages/vault";

import { CurationProductFragment } from "../../../gql-request";
import { Banner } from "../../../hooks";
import { LabelList } from "../../../product/V2/LabelList";
import { ProductRating } from "../../../product/V2/ProductReviewsInfoV2";
import { ProductInfoTagsV2 } from "../../../product/V2/ProductTagsV2";
import { ProductCardContentMode } from "../../../product/types";
import "../../../types";
import { FavouriteButton } from "../../FavouriteButton";
import { MarketPlaceMinPrice } from "../../MarketPlaceMinPrice";
import { PriceType, PricingData } from "../../Price";
import { Ribbon } from "../../Ribbon";
import { Tag, TagProps } from "../../TagList";
import {
    ImageTileContainer,
    ImageTileHeaderContainer,
    TileContentDetailsWrapper,
    TileWrapperHorizontal,
    TitleText,
    DEFAULT_IMAGE_HEIGHT,
    MAX_TILE_IMAGE_WIDTH,
    TileContentDetailsSpacingWrapper,
    MarketPlaceProductTileImageHorizontalContainer,
    TileWrapper,
} from "./MarketPlaceProductTileLayoutComponents";

export type MarketPlaceProductTileProps = {
    title: string;
    href: string;
    showFavouriteButton?: boolean;
    isFavourite?: boolean;
    newTab?: boolean;
    guidePriceFormattedText?: string;
    guidePriceType?: PriceType;
    guidePricePricingData?: PricingData;
    minDuration?: string;
    maxDuration?: string;
    reviewCount?: number;
    reviewRating?: number;
    tagLabel?: string;
    hasFreeCancellation?: boolean;
    height?: number;
    banner?: Banner | null;
    holibobGuidePrice?: CurationProductFragment["holibobGuidePrice"];
    // image related props
    imageAssetUrl?: AssetUrl;
    imageMaxWidth?: number;
    imageMinWidth?: number;

    isSmallTile?: boolean;

    onTagClick?: TagProps["onClick"];
    onFavouriteChange?: (isFavourite: boolean) => void;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

const MIN_CARD_WIDTH_WITH_REVIEW_COUNT = 300;

export type MarketPlaceProductTileVerticalProps = MarketPlaceProductTileProps & {
    tileContentMode?: ProductCardContentMode;
};

export const MarketPlaceProductTileVertical = ({ ...props }: MarketPlaceProductTileVerticalProps) => {
    const { imageMaxWidth = MAX_TILE_IMAGE_WIDTH } = props;

    return (
        <TileWrapper
            href={props.href}
            newTab={props.newTab}
            sx={{ maxWidth: imageMaxWidth }}
            data-testid="productCard"
            data-is-small-tile={props.isSmallTile}
            onClick={props.onClick}
        >
            <MarketPlaceProductTileHorizontalImage {...props} withDimOverlay />
            <TileContentDetailsSpacingWrapper>
                <TileContentHorizontalV2
                    {...props}
                    reviewCount={imageMaxWidth > MIN_CARD_WIDTH_WITH_REVIEW_COUNT ? props.reviewCount : undefined}
                />
            </TileContentDetailsSpacingWrapper>
        </TileWrapper>
    );
};

const MarketPlaceProductTileHorizontalImage = (props: MarketPlaceProductTileImageProps) => {
    return (
        <MarketPlaceProductTileImageHorizontalContainer>
            <MarketPlaceProductTileImage {...props} withDimOverlay />
        </MarketPlaceProductTileImageHorizontalContainer>
    );
};

const TileContentHorizontal = (props: MarketPlaceProductTileProps) => {
    const { guidePricePricingData, holibobGuidePrice } = props;
    return (
        <TileContentDetailsWrapperHorizontal>
            <Box display="flex" flexDirection="column" gap={1} flex={1}>
                <TitleHorizontal title={props.title} />

                <ProductInfoTagsV2
                    instantConfirmation
                    minDuration={props.minDuration}
                    maxDuration={props.maxDuration}
                    hasFreeCancellation={props.hasFreeCancellation}
                />
            </Box>
            <Box display="flex" justifyContent="space-between" gap={1} alignItems="center">
                <ProductReview reviewCount={props.reviewCount} reviewRating={props.reviewRating} />
                {!!props.guidePriceFormattedText && (
                    <MarketPlaceMinPrice
                        data-testid="product-price"
                        priceType={props.guidePriceType}
                        minPrice={{
                            amount: holibobGuidePrice?.gross ?? 0,
                            currency: holibobGuidePrice?.currency ?? "GBP",
                        }}
                        guidePricePricingData={guidePricePricingData}
                    />
                )}
            </Box>
        </TileContentDetailsWrapperHorizontal>
    );
};

const TileContentHorizontalV2 = (props: MarketPlaceProductTileProps) => {
    const { guidePricePricingData, holibobGuidePrice } = props;
    return (
        <TileContentDetailsWrapper>
            <Box display="flex" flexDirection="column" gap={1} flex={1}>
                <TitleHorizontal title={props.title} />
                <ProductInfoTagsV2 minDuration={props.minDuration} maxDuration={props.maxDuration} />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <ProductReview reviewCount={props.reviewCount} reviewRating={props.reviewRating} />
                {!!props.guidePriceFormattedText && (
                    <MarketPlaceMinPrice
                        data-testid="product-price"
                        priceType={props.guidePriceType}
                        minPrice={{
                            amount: holibobGuidePrice?.gross ?? 0,
                            currency: holibobGuidePrice?.currency ?? "GBP",
                        }}
                        guidePricePricingData={guidePricePricingData}
                    />
                )}
            </Box>
        </TileContentDetailsWrapper>
    );
};

export const MarketPlaceProductHorizontal = ({ onClick, ...props }: MarketPlaceProductTileProps) => {
    return (
        <TileWrapperHorizontal
            href={props.href}
            newTab={props.newTab}
            data-is-small-tile={props.isSmallTile}
            onClick={onClick}
        >
            <MarketPlaceProductTileImage {...props} withDimOverlay={false} />
            <TileContentHorizontal {...props} />
        </TileWrapperHorizontal>
    );
};

const SIZES = [300, 500, 700, 900, 1100, 1300];

type MarketPlaceProductTileImageProps = Pick<
    MarketPlaceProductTileProps,
    | "imageAssetUrl"
    | "showFavouriteButton"
    | "isFavourite"
    | "title"
    | "tagLabel"
    | "onTagClick"
    | "height"
    | "onFavouriteChange"
    | "banner"
    | "isSmallTile"
> & {
    withDimOverlay?: boolean;
};
const MarketPlaceProductTileImage = (props: MarketPlaceProductTileImageProps) => {
    const {
        title,
        imageAssetUrl,
        height = DEFAULT_IMAGE_HEIGHT,
        showFavouriteButton,
        isFavourite,
        onFavouriteChange,
        tagLabel,
        onTagClick,
        banner,
        isSmallTile,
    } = props;
    const imageTileProp = isSmallTile
        ? { maxWidth: 250 }
        : { srcSetSizes: SIZES, sizes: "(min-width: 600px) 330px, calc(100vw - 50px)" };
    return (
        <ImageTileContainer
            data-testid="product-image"
            data-ref="product-image"
            data-with-overlay={props.withDimOverlay}
            imageAssetUrl={imageAssetUrl}
            alt={title}
            height={height}
            hoverOpacity={0.8}
            hoverZoomScale={1.1}
            data-image-tile-container="true"
            {...imageTileProp}
        >
            <ImageTileHeaderContainer>
                {/*`
                    Favourite button needs to be wrapped in div,
                    in case when it's hidden, tags should remain aligned to the right
                */}
                <div>
                    {showFavouriteButton && (
                        <FavouriteButton
                            size="small"
                            isFavourite={isFavourite ?? false}
                            onChange={onFavouriteChange}
                            color="light"
                        />
                    )}
                </div>
                {banner && <Ribbon color={banner.backgroundColor}>{banner.text}</Ribbon>}
                {tagLabel && (
                    <Tag onClick={onTagClick ? onTagClick : undefined} disabled={!onTagClick}>
                        {tagLabel}
                    </Tag>
                )}
            </ImageTileHeaderContainer>
        </ImageTileContainer>
    );
};

type TitleProps = { title: string; className?: string };
const Title = ({ title, className }: TitleProps) => {
    return (
        <TitleText variant="title" className={className} data-testid="product-title">
            {title}
        </TitleText>
    );
};

type ReviewDurationProps = {
    reviewCount?: number;
    reviewRating?: number;
    minDuration?: string;
    maxDuration?: string;
};

const ProductReview = (props: ReviewDurationProps) => {
    const { reviewCount, reviewRating } = props;

    if (!reviewRating) {
        return <span />;
    }

    return (
        <LabelList data-testid="product-review">
            <ProductRating reviewCount={reviewCount} reviewRating={reviewRating} />
        </LabelList>
    );
};

export const TitleHorizontal = styled(Title)({
    textAlign: "start",
    WebkitLineClamp: 2,
});

const TileContentDetailsWrapperHorizontal = styled(TileContentDetailsWrapper)(({ theme }) => ({
    padding: theme.spacing(2),
    paddingBlockStart: 0,
    [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(2),
        paddingInlineStart: 0,
    },
}));
