import {
    TileWrapperHorizontal,
    TileContentDetailsWrapper,
    ImageSkeleton,
    FixedSkeleton,
} from "./MarketPlaceProductTileLayoutComponents";

export const MarketPlaceProductTileHorizontalSkeleton = () => {
    const maxHeight = 180;
    return (
        <TileWrapperHorizontal href="" newTab={false} sx={{ height: maxHeight }} data-is-small-tile={true}>
            <ImageSkeleton variant="rounded" width={180} height={maxHeight} />
            <TileContentDetailsWrapper>
                <FixedSkeleton variant="rectangular" height={25} />
                <FixedSkeleton variant="rectangular" height={25} />
                <FixedSkeleton variant="rectangular" height={25} />
            </TileContentDetailsWrapper>
        </TileWrapperHorizontal>
    );
};
