import { isNamedColour, NamedColour, SpotColourRef } from "@holibob-packages/colour";

import { Palette } from "../contexts/BrandedThemeProvider/material-ui/createPalette";
import { SpotColours } from "../style";

// eslint-disable-next-line @typescript-eslint/ban-types
type HexColour = string & {};

export type ColourToResolve = HexColour | NamedColour | SpotColourRef;

export function resolveColour(colour: ColourToResolve, palette: Palette) {
    if (typeof colour === "string") {
        if (colour.startsWith("#")) {
            return colour;
        }
        if (isNamedColour(colour)) {
            return resolveNamedColour(colour, palette);
        }
        return colour;
    }

    return resolveSpotColourRef(colour, palette.spotColours);
}

function resolveSpotColourRef(ref: SpotColourRef, spotColours: SpotColours) {
    return spotColours.get(ref.color)?.[ref.shade];
}

function resolveNamedColour(colourName: NamedColour, palette: Palette): string | undefined {
    if (colourName === "primary") {
        return resolveSpotColourRef({ color: -2, shade: 100 }, palette.spotColours);
    } else if (colourName === "primaryLight") {
        return resolveSpotColourRef({ color: -2, shade: 70 }, palette.spotColours);
    } else if (colourName === "primaryDark") {
        return resolveSpotColourRef({ color: -2, shade: 400 }, palette.spotColours);
    } else if (colourName === "secondary") {
        return resolveSpotColourRef({ color: -1, shade: 100 }, palette.spotColours);
    } else if (colourName === "secondaryLight") {
        return resolveSpotColourRef({ color: -1, shade: 70 }, palette.spotColours);
    } else if (colourName === "secondaryDark") {
        return resolveSpotColourRef({ color: -1, shade: 400 }, palette.spotColours);
    } else if (colourName === "text") {
        return palette.text?.primary;
    } else if (colourName === "textSecondary") {
        return palette.text?.secondary;
    } else if (colourName === "black" || colourName === "dark") {
        return palette.common?.black ?? "#000000";
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (colourName === "white" || colourName === "light") {
        return palette.common?.white ?? "#ffffff";
    }
    return;
}

export type ColourResolver = (colour: ColourToResolve) => string | undefined;
